<script>
import IconClose from '@/assets/icon-close.vue'

import Spinner from '@/components/Spinner'

import IconSuccess from '@/assets/icon-sucessfully.vue'

export default {
  name: 'ConfirmCreateModal',
  components: {
    IconClose,
    IconSuccess,
    Spinner,
  },
  props: {
    message: {
      required: true,
    },
  },
  emits: ['confirm', 'close'],
  data() {
    return {
      disable: false,
    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    confirm() {
      this.disable = true
      this.$emit('confirm')
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<template>
  <transition name="modal-fade">
    <div v-show="true" class="modal-backdrop">
      <div
        class="modal-wallid message"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        @click.stop
      >
        <header
          v-if="message !== 'storing'"
          id="modalTitle"
          class="modal-header"
          :class="message === 'confirm' ? '--text-header' : ''"
        >
          <slot name="header">
            <span v-if="message === 'confirm'">
              {{ $t(`modals.${message}.title`) }}
            </span>
            <v-spacer />
            <button
              type="button"
              class="btn-close"
              aria-label="Close modal"
              @click="close"
            >
              <IconClose />
            </button>
          </slot>
        </header>

        <div class="modal-body text-center pa-7">
          <slot name="body">
            <Spinner v-if="message === 'storing'" />
            <IconSuccess
              v-if="
                message === 'credentials'
                  || message === 'credential'
                  || message === 'email'
                  || message === 'successCreateTemplate'
              "
            />

            <h2
              v-if="message !== 'confirm'"
              class="subtitle_header mx-auto pa-4"
            >
              {{ $t(`modals.${message}.title`) }}
            </h2>
            <p class="subtitle_text px-16">
              {{ $t(`modals.${message}.text`) }}
            </p>
          </slot>
        </div>

        <div v-if="message === 'confirm'" class="modal-footer">
          <slot name="footer">
            <v-row class="justify-end" no-gutters>
              <v-btn class="back mr-4" @click="close()">
                {{
                  $t(`modals.${message}.cancel`)
                }}
              </v-btn>
              <v-btn
                class="next"
                :loading="disable"
                :disabled="disable"
                @click="confirm()"
              >
                {{ $t(`modals.${message}.confirm`) }}
              </v-btn>
            </v-row>
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss">
.modal-wallid.message {
  position: relative;
  .v-stepper {
    box-shadow: none;
  }
  .modal-header {
    border-bottom: none !important ;
    padding-bottom: 0 !important;
    &.--text-header {
      padding-bottom: 20px !important;
      padding-top: 20px !important;
      border-bottom: solid 1px #d8d8d8 !important;
    }
  }
  .modal-footer {
    bottom: 0px;
    width: 100%;
  }
}
</style>
